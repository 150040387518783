import Cookies from 'js-cookie'

const secure = window.location.protocol === 'https:'

const GDPR_MESSAGE_CHECKED_AT_COOKIE = 'gdpr_message_checked_at_20240528'

export const checkGdprMessage = () => {
  const now = new Date()
  Cookies.set(GDPR_MESSAGE_CHECKED_AT_COOKIE, now.getTime().toString(), {
    expires: 365 * 20,
    secure: secure
  })
}

export const checkedGdprMessage = () => Cookies.get(GDPR_MESSAGE_CHECKED_AT_COOKIE) === undefined
