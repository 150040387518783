import React from 'react'
import ClickOutside from 'react-click-outside'
import {SearchModal} from 'components/SearchModal'
import {Genre, Category, Label} from './Type'
import Icon from './Icon'

interface Props {
  genres: Array<Genre>,
  categories: Array<Category>,
  labels: Array<Label>
}

interface State {
  isOpenModal: boolean
}

export default class HeaderWithSearchModal extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      isOpenModal: false
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.isOpenModal !== this.state.isOpenModal) {
      document.documentElement.classList.toggle('auto', this.state.isOpenModal)
      document.getElementById('body-wrapper').classList.toggle('blur', this.state.isOpenModal)
    }
  }

  public openModal = () => {
    this.setState({
      isOpenModal: true
    })
  }

  public closeModal = (event) => {
    // NOTE: openModalやリンクをクリックした場合の挙動を止める
    if (this.state.isOpenModal) {
      event.stopPropagation()
      event.preventDefault()
    }

    this.setState({
      isOpenModal: false
    })
  }

  public handleClickOutside = event => {
    if (this.state.isOpenModal) { this.closeModal(event) }
  }

  renderSearchForm() {
    return (
      <div className="search-wrapper" onClick={this.openModal}>
        <form className="search-wrapper-inner" action="/search" method="get" autoComplete="off">
          <input className="search-input" placeholder="作品名・作者名で検索" type="text" name="keyword" />
          <Icon name="search" size="24px" className="search-image fill-text-secondary" />
        </form>
      </div>
    )
  }

  public render() {
    return(
      <ClickOutside onClickOutside={this.handleClickOutside} className="search">
        {this.renderSearchForm()}
        <SearchModal {...this.props} isOpenModal={this.state.isOpenModal} closeModal={this.closeModal}/>
      </ClickOutside>
    )
  }
}
