import React from 'react'
import cn from 'classnames'
import ButtonCell from 'components/DrawerButtonCell'
import {Notice} from 'components/Type'
import Icon from 'components/Icon'
import {isLogin} from 'packs/checkLogin'

interface Props {
  userName?: string,
  userImageUrl?: string,
  loginUrl?: string,
  logoutUrl?: string,
  latestNotice?: Notice,
  isOpenDrawer: boolean
}

export const Drawer = (props: Props) => {
  return (
    <div className={cn({"drawer": true, "show": props.isOpenDrawer})}>
      <div className="drawer-menu">
        {isLogin(props) ? <User userName={props.userName} userImageUrl={props.userImageUrl}/> : <Login loginUrl={props.loginUrl} />}

        <Notice latestNotice={props.latestNotice}/>

        <hr />

        <ButtonCell iconName="label" href="/labels">
          レーベル一覧
        </ButtonCell>

        <ButtonCell iconName="ranking" href="/ranking">
          週間ランキング
        </ButtonCell>

        {isLogin(props) ? <Follow /> : ''}

        <hr />

        <ButtonCell iconName="help" href="https://novel.pixiv.help/hc/ja">
          ヘルプ
        </ButtonCell>

        {isLogin(props) && <hr />}

        {isLogin(props) && <Logout logoutUrl={props.logoutUrl} />}
      </div>
    </div>
  )
}

const User = (props) => (
  <div className="user">
    <div className="user-info">
      <img src={props.userImageUrl} className="profile-image"></img>
      <div className="user-name">
        {props.userName}
      </div>
    </div>
  </div>
)

const Notice = (props) => (
  <div className="notice">
    <div className="notice-headline">
      <div className="notice-text">
        お知らせ
      </div>
      <a className="notice-button" href="/notices">
        もっと見る
        <div className="notice-icon">
          <Icon name="arrow_right" size="16px" className="fill-brand-primary icon" />{' '}
        </div>
      </a>
    </div>
    <div className="notice-content">
      <div className="top-pane">
        <div className="notice-category">
          {props.latestNotice.category}
        </div>
        <div className="notice-publish-date">
          {props.latestNotice.publish_date}
        </div>
      </div>
      <div className="notice-title">
        <a href={`/notices/${props.latestNotice.id}`}>
          {props.latestNotice.title}
        </a>
      </div>
    </div>
  </div>
)

const Logout = (props) => (
  <ButtonCell iconName="logout" href={props.logoutUrl}>
    ログアウト
  </ButtonCell>
)

const Follow = () => (
  <ButtonCell iconName="following" href="/followed">
    フォロー
  </ButtonCell>
)

const Login = (props) => (
  <div className="login-btn-area">
    <a href={props.loginUrl} className="button-40px primary">
      ログイン
    </a>
  </div>
)
