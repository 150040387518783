import React from 'react'
import cn from 'classnames'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import {Genre, Category, Label} from './Type'
import Icon from './Icon'

interface Props {
  genres: Array<Genre>,
  categories: Array<Category>,
  labels: Array<Label>,
  isOpenModal: boolean,
  closeModal: Function
}

export class SearchModal extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <div className={cn("search-modal", {"show": this.props.isOpenModal})}>
        {this.renderCloseButton(this.props.closeModal)}
        <Tabs>
          <TabList className="tabs">
            <Tab className="tabs-button" selectedClassName="tabs-button--active">ジャンル</Tab>
            <Tab className="tabs-button" selectedClassName="tabs-button--active">レーベル</Tab>
          </TabList>

          <TabPanel>
            <div className="modal-content-wrapper">
              <p className="header">ジャンルで見つける</p>
              <div className="panels">
                {this.renderPanels(this.props.genres)}
              </div>
              <p className="header">カテゴリーで見つける</p>
              <div className="panels">
                {this.renderPanels(this.props.categories)}
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="modal-content-wrapper">
              <p className="header">レーベルで見つける</p>
              <div className="labels">
                {this.renderLabels()}
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    )
  }

  renderCloseButton(event) {
    return (
      <div className="close-wrapper">
        <div className="close-button">
          <div onClick={event}>
            <Icon name="close" size="32px" className="close fill-text-secondary" />
          </div>
        </div>
      </div>
    )
  }

  renderPanels(items) {
    return items.map((item) =>
      <a href={item.path} className="panel" key={item.name}>
        <div className="panel-inner">
          {item.name}
          <br />
          ({item.count})
        </div>
      </a>
    )
  }

  renderLabels() {
    return this.props.labels.map((label) =>
      <a href={label.path} className="label-item" key={label.id}>
        <img src={label.logo_image_url}/>
      </a>
    )
  }
}
