import React from 'react'
import Icon from 'components/Icon'
import {isLogin} from 'packs/checkLogin'

export const HeaderInnerPC = (props) => (
  <div className="hamburger-wrapper" onClick={props.onClick}>
    <Icon name="hamburger" size="24px" className="hamburger-image fill-white" />
  </div>
)

export const FollowIcon = (props) => (
  <div className="right-pane">
    {isLogin(props) ? <Follow /> : ''}
  </div>
)

const Follow = () => (
  <div className="following-wrapper"> <a href="/followed">
      <Icon name="following" size="24px" className="following-image fill-white" />
    </a>
  </div>
)
