const checkLogin = function(target) {
	if (!target.classList.contains('require-login')) return true
	window.confirm('"pixivにログイン"する必要があります。ログインしますか？') && (location.href = target.dataset.url)
	return false
}

const isLogin = (props: any): boolean  => {
  return props.userName ? true : false
}

export {checkLogin, isLogin}
