import React from 'react'

const MESSAGE = '2024年5月28日付でプライパシーポリシーを改定しました。'
const PRIVACY_POLICY_URL = 'https://policies.pixiv.net/ja/privacy_policy.html'

type Props = {
  clickFn: (event: React.MouseEvent<HTMLAnchorElement>) => void
  mobile?: boolean
}

const GdprMessage: React.SFC<Props> = ({
  clickFn,
  mobile = false
}) => (
  <div className="gdpr">
    <div className="gdpr-inner">
      <p className="gdpr-message">
        {mobile ? <a href={PRIVACY_POLICY_URL} target="_blank" rel="noopenner">{MESSAGE}</a> : MESSAGE}
      </p>
      <div className="gdpr-btn-box">
        {!mobile && <a className="gdpr-btn button-40px secondary" href={PRIVACY_POLICY_URL} target="_blank" rel="noopenner">詳細</a>}
        <a className="gdpr-btn button-40px primary" onClick={clickFn}>OK</a>
      </div>
    </div>
  </div>
)

export default GdprMessage
