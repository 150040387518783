import React from 'react'
import ReactDOM from 'react-dom'
import {HeaderInnerPC, FollowIcon} from 'packs/headers/components/HeaderInnerPC'
import HeaderWithSearchModal from 'components/HeaderWithSearchModal'
import {Notice, Genre, Category, Label} from 'components/Type'
import GdprMessage from 'components/GdprMessage'
import ClickOutside from 'react-click-outside'
import {Drawer} from 'components/Drawer'
import {checkGdprMessage, checkedGdprMessage} from 'common'

type Props = {
  userName?: string
  userImageUrl?: string
  loginUrl?: string
  logoutUrl?: string
  latestNotice?: Notice
  genres: Array<Genre>
  categories: Array<Category>
  labels: Array<Label>
}

type State = {
  isOpenGdprMessage: boolean
  isOpenDrawer: boolean
}

class HeaderPc extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      isOpenGdprMessage: false,
      isOpenDrawer: false
    }
  }

  componentDidMount() {
    if (checkedGdprMessage()) {
      this.setState({isOpenGdprMessage: true})
    } else {
      this.setState({isOpenGdprMessage: false})
    }
  }

  render() {
    const {
      userName,
      userImageUrl,
      loginUrl,
      logoutUrl,
      latestNotice,
      genres,
      categories,
      labels
    } = this.props
    const { isOpenGdprMessage, isOpenDrawer } = this.state

    return (
      <>
        {isOpenGdprMessage && <GdprMessage clickFn={this.gdprMessageClickFn} />}
        <div className="header-inner">
          <HeaderInnerPC userName={userName} onClick={this.openDrawer}/>
          <div className="logo-wrapper">
            <a href="/">
              <img src="/images/logo.png" className="logo-image" />
            </a>
          </div>
          <HeaderWithSearchModal genres={genres} categories={categories} labels={labels} />
          <FollowIcon userName={userName}/>
        </div>
        {isOpenDrawer &&  <div className="drawer-background" />}
        <ClickOutside onClickOutside={this.closeDrawer} >
          <Drawer
           userName={userName}
           userImageUrl={userImageUrl}
           loginUrl={loginUrl}
           logoutUrl={logoutUrl}
           latestNotice={latestNotice}
           isOpenDrawer={isOpenDrawer}
          />
        </ClickOutside>
      </>
    )
  }

  private gdprMessageClickFn = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation()
    event.preventDefault()

    checkGdprMessage()
    this.setState({
      isOpenGdprMessage: false
    })
  }

  private openDrawer = () => {
    this.setState({
      isOpenDrawer: true
    })
  }

  private closeDrawer = () => {
    this.setState({
      isOpenDrawer: false
    })
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const userName = (window as any).const.login_user_name
  const userImageUrl = (window as any).const.login_user_image
  const loginUrl = (window as any).const.login_url
  const logoutUrl = (window as any).const.logout_url

  const $notice: HTMLElement = document.querySelector('.notice')
  const latestNotice: Notice = JSON.parse($notice.dataset.notice)

  const $genres: HTMLElement = document.querySelector('.search-genres')
  const genres: Array<Genre> = JSON.parse($genres.dataset.genres)
  const $categories: HTMLElement = document.querySelector('.search-categories')
  const categories: Array<Category> = JSON.parse($categories.dataset.categories)
  const $labels: HTMLElement = document.querySelector('.search-labels')
  const labels: Array<Label> = JSON.parse($labels.dataset.labels)

  const props = {
    userName,
    userImageUrl,
    loginUrl,
    logoutUrl,
    latestNotice,
    genres,
    categories,
    labels
  }

  ReactDOM.render(
    <HeaderPc {...props} />,
    document.querySelector('#header'))
})
